import { EmployeeParking } from '../parking/parking.model';

export const FLEX = 'flex';
export const UNAVAILABLE = 'unavailable';
export interface Bookable {
  icon: string;
  color?: string;
  resourceNameAlias?: string;
  nameAlias?: string;
  category: string;
  assetName: string;
  levelID: number;
  levelName: string;
  resourceID?: number;
  resourceName: string;
  zoneID?: number;
  zoneName?: string;
}
export interface Entry extends Bookable {
  className?: string;
  name: string;
  identifier: string;
  favorite?: boolean;
  favoriteSortOrder: number;
  totalResourceCount?: number;
  usedResourceCount?: number;
  entryType: string;
  entryID: number;
  quickBooking?: boolean;
  occupied?: number[];
  // Convenience properties when converting from booking to entry group
  parentEntryType?: string;
  parentEntryID?: number;
  fromMs?: number;
  toMs?: number;
  mapID?: number;
  isAvailable?: boolean;
  isAutoBooking?: boolean;
}

export interface EntryDetails extends Bookable {
  originalName: string;
  entryType: string;
  entryID: number;
  mapID: number;
  assetGroupName: string;
  assetName: string;
  assetImageUrl: string;
  sectionName: string;
  levelName: string;
  zoneName: string;
  resourceName: string;
}

export interface CalendarDay {
  isHoliday: boolean;
  isAutoBooking: boolean;
  fromMs: number;
  toMs: number;
  bookings: Booking[];
  tempBookings?: Booking[];
  colleague?: Booking[];
  available?: boolean;
}
export interface Booking extends Bookable {
  isAutoBooking?: boolean;
  exception?: string;
  sectionName: string;
  entryType: string;
  entryID: number;
  mapID: number;
  assetImageUrl: string;
  withLunch: boolean;
  withParking: boolean;
  storeAsQuickBooking: boolean;
  storeAsAutoBooking: boolean;
  markDelete?: boolean;
  endTimeMs?: number;
  startTimeMs?: number;
  favorite?: boolean; // Present when read from api
  storeAsFavorite?: boolean; // Present only when booking is created from search dialog.
  bookingID?: number;
  resourceID: number;
  resourceNameAlias?: string;
  category: string;
  fromMs: number;
  toMs: number;
  employeeParking?: EmployeeParking;
}

export interface TeamBookings {
  employeeID: number;
  name: string;
  imageUrl: string;
  booking: Booking[];
}

export interface Paged<T> {
  pageSize: number;
  pageStart: number;
  totalPages: number;
  data: T;
}

export interface FavoriteSort {
  entryType: string;
  entryID: number;
  sortOrder: number;
}

export interface CategoryOverview {
  left: Category[];
  right: Category[];
}
export interface Category {
  style: { width: string };
  color: string;
  count: number;
  side: 'l' | 'r';
}

export interface BookedResources {
  endTime: string;
  endTimeMs: number;
  resourceID: number;
  startTime: string;
  startTimeMs: number;
}

export interface TimeTuple {
  fromMs: number;
  toMs: number;
}

export interface BookedResourceAvailability {
  resourceID: number;
  startTimeMs: number;
  endTimeMs: number;
}

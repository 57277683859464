import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { ThemeService } from '../theme';
import { SharedObservable, getEnv, retryOn504 } from '../utils';
import { MapConfig, MapDoorElement, MapLabelElement, MapResource, MapSymbolElement, MapZoneElement } from './map.model';
import { IMapSymbol, MapSymbol, getSymbol } from './symbols';

// eslint-disable-next-line no-useless-escape
export const VIEWBOX_REGEX = /viewBox=["|']([0-9\.\s]+)["|']/gm;

export enum Limit {
  WARNING = 'warning',
  NOTGOOD = 'not-good',
  GOOD = 'good',

  WARMEST = 'warmest',
  WARM = 'warmer',
  COLD = 'cool',

  NA = 'notActive',
}

@Injectable({
  providedIn: 'root',
})
export class MapService {
  theme = inject(ThemeService);

  private numberFormatter = new Intl.NumberFormat('nb-NO', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  debug$ = new BehaviorSubject('');

  symbols: IMapSymbol[] = []; // All the symbols used

  limits: { [key: string]: (val: number, variant?: boolean) => Limit } = {
    co2: (val: number): Limit => (val >= 1000 ? Limit.WARNING : val >= 800 ? Limit.NOTGOOD : Limit.GOOD),

    humidity: (val: number): Limit =>
      val >= 70
        ? Limit.WARNING
        : val >= 60
          ? Limit.NOTGOOD
          : val >= 30
            ? Limit.GOOD
            : val >= 25
              ? Limit.NOTGOOD
              : Limit.WARNING,

    tvoc: (val: number): Limit => (val >= 2000 ? Limit.WARNING : val >= 250 ? Limit.NOTGOOD : Limit.GOOD),

    temperature: (val: number, tempCol = true): Limit =>
      val >= 23
        ? tempCol
          ? Limit.WARMEST
          : Limit.WARNING
        : val >= 19
          ? tempCol
            ? Limit.WARM
            : Limit.GOOD
          : tempCol
            ? Limit.COLD
            : Limit.NOTGOOD,

    radon: (val: number): Limit => (val >= 150 ? Limit.WARNING : val >= 100 ? Limit.NOTGOOD : Limit.GOOD),

    pressure: (val: number): Limit => Limit.GOOD,
    // return val >= 150 ? Limit.WARNING
    //   : val >= 100    ? Limit.NOTGOOD
    //   : Limit.GOOD;

    light: (val: number): Limit => Limit.GOOD,
    // return val >= 150 ? Limit.WARNING
    //   : val >= 100    ? Limit.NOTGOOD
    //   : Limit.GOOD;
  };

  constructor(
    private http: HttpClient,
    // private customer: CustomerService,
    // private app: AppService,
    private sec: DomSanitizer,
  ) {
    this.symbols = MapSymbol.map(r => getSymbol(r.id, this.sec));
  }

  debug(msg: string) {
    // this.debug$.next(msg);
    // setTimeout(() => this.debug$.next(''), 1000);
  }

  flatMap(config: MapConfig, markResourceID: number) {
    return this.http.post(
      `/api/map/`,
      {
        mapConfig: config,
        markResourceID,
        showNeighborhoods: true,
        theme: this.theme.colorScheme,
      },
      { responseType: 'blob' },
    );
  }

  /**
   * This method must work on all systems.
   * - Logic-Suite requires us to have a customerID in the query, since a user can have access to multiple customers.
   * - Flex does not have a customerID since a user can only have access to one customer.
   * - Officemap has the customerID in the URL as a hashed token.
   *
   * @param mapID The mapID to load
   * @returns A MapConfig object
   */
  @SharedObservable()
  getConfig(url: string): Observable<MapConfig> {
    return this.http
      .get<MapConfig>(url.replace('//', '/'))
      .pipe(retryOn504())
      .pipe(
        map(config => {
          // Filter out null values
          config.resources = config.resources?.filter(r => r != null) ?? [];
          config.neighborhoods =
            config.neighborhoods
              ?.filter(n => n != null)
              .map(n => Object.assign(n, { vectors: n.vectors?.filter(v => v != null) ?? [] })) ?? [];
          config.meetingRooms =
            config.meetingRooms
              ?.filter(m => m != null)
              .map(m => Object.assign(m, { vectors: m.vectors?.filter(v => v != null) ?? [] })) ?? [];
          return config;
        }),
      );
  }

  getCoordinates(config: MapConfig) {
    VIEWBOX_REGEX.lastIndex = 0;
    const match = VIEWBOX_REGEX.exec(config.floorPlanSVG);
    if (match && match.length > 0) {
      // Derive coordinates from floorPlans viewBox
      return match[1];
    } else {
      // ... or set the coordinate system as defined by the floorPlan, or a default
      return config.coordinateSystem || '0 0 2000 500';
    }
  }

  private _getColor(key: Limit) {
    const color = getComputedStyle(document.body).getPropertyValue(`--${key}`).trim();
    return color.indexOf('rgb') > -1 ? this.rgbStrToHex(color) : color;
  }

  getColor(type: string, value: string) {
    return this._getColor(this.getLimit(type, value));
  }

  getLimit(type: string, value: string | undefined): Limit {
    if (value == null) return Limit.NA;

    const num = parseInt(this.numberFormatter.format(+value));
    return this.limits[type](num);
  }

  /**
   * Convert a `rgb(rrr, ggg, bbb)` string value to a hexadecimal color code.
   * Will also accept alpha.
   *
   * Because react-scripts turn hex with alpha to `rgba` at build time and I cannot find
   * any way to disable this behavior, and svg color-stops does not render `rgba`,
   * we need to convert it back at runtime.
   */
  private rgbStrToHex(rgb: string) {
    const [r, g, b, a] = rgb
      .trim()
      .substring(rgb.indexOf('(') + 1, rgb.indexOf(')'))
      .split(',')
      .map(n => +n);
    return this.rgbToHex(r, g, b, a);
  }

  /**
   * Convert red, green and blue values to a hexadecimal color code.
   * Will also accept alpha
   */
  private rgbToHex(r: number, g: number, b: number, a?: number): string {
    return (
      '#' +
      [r, g, b, a]
        .filter(x => x != null) // Remove null
        .map(x => x?.toString(16).padStart(2, '0') ?? x)
        .join('')
    );
  }

  configureMap(config: MapConfig) {
    return {
      coordinates: this.getCoordinates(config),
      useHoodColorsOnDesks: config.showNeighborhoodColors ? config.showNeighborhoodColors : getEnv('showHoodColors'),
      config,
      ...this.setResources(structuredClone(config.resources)),
    };
  }

  /**
   * Create all symbols
   */
  setResources(resources: MapResource[]) {
    if (!Array.isArray(resources)) resources = [];

    // Set resources
    return {
      resources: (resources.filter(r => !['zone', 'icon', 'door', 'label'].some(t => r.type?.startsWith(t))) ?? []).map(
        r => {
          const symbol = this.symbols.find(s => s.id === r.type);
          const resource = Object.assign(r, {
            width: symbol?.width ?? 2,
            height: symbol?.height ?? 2,
            layer: 'resources',
            transform: this.applyTransform(r),
            filter: this.getFilter(r),
          }) as MapSymbolElement;
          resource.style = `transform: ${r.transform}; transform-origin: ${r.width! / 2}px ${r.height! / 2}px;`;
          return resource;
        },
      ),

      // Set icons
      icons: (resources.filter(r => r.type?.startsWith('icon')) ?? []).map(r => {
        const symbol = this.symbols.find(s => s.id === r.type);
        const resource = Object.assign(r, {
          width: symbol?.width ?? 2,
          height: symbol?.height ?? 2,
          layer: 'icons',
          transform: this.applyTransform(r),
        }) as MapSymbolElement;
        resource.style = `transform: ${r.transform}; transform-origin: ${r.width! / 2}px ${r.height! / 2}px;`;
        return resource;
      }),

      // Set doors
      doors: (resources.filter(r => r.type?.startsWith('door')) ?? []).map(r => {
        return Object.assign(r, { layer: 'doors' }) as MapDoorElement;
      }),

      // Set zones
      zones: (resources.filter(r => r.type?.startsWith('zone')) ?? []).map(r => {
        return Object.assign(r, {
          layer: 'zones',
          transform: this.applyTransform(r),
          filter: this.getFilter(r),
        }) as MapZoneElement;
      }),

      labels: (resources.filter(r => r.type === 'label') ?? []).map(r => {
        return Object.assign(r, {
          layer: 'label',
          scale: r.scale || 1,
          rotate: r.rotate || 0,
          transform: this.applyTransform(r),
        }) as MapLabelElement;
      }),
    };
  }

  applyPosition(r: MapResource, offsetX = 0, offsetY = 0): string {
    const x = r.x + (r.width || 1) / 2;
    const y = r.y;
    return `transform: translate(${x + offsetX}px, ${y + offsetY}px`;
  }

  optimizeX(elX: number, el: HTMLElement, offset = 10, r: string) {
    const canvas = el.closest('svg') as SVGSVGElement;
    const [newX, newY] = this.optimizeTextPosition(r, canvas, elX, 0, offset, 0, 0);
    return newX;
  }

  optimizeY(elY: number, el: HTMLElement, offset = 10, r: string) {
    const canvas = el.closest('svg') as SVGSVGElement;
    const [newX, newY] = this.optimizeTextPosition(r, canvas, 0, elY, offset, 0, 0);
    return newY;
  }

  dimensionCache = new Map<number, DOMRect>();
  getDimensions(str: string, canvas: SVGSVGElement) {
    if (!str) return new DOMRect(0, 0, 0, 0);
    if (this.dimensionCache.has(str.length)) return this.dimensionCache.get(str.length)!;
    // Create a dummy element for the text
    const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    text.innerHTML = str;
    canvas.appendChild(text);
    const result = text.getBBox();
    text.remove();

    this.dimensionCache.set(str.length, result);
    return result;
  }

  optimizeTextPosition(
    str: string, // The string to calculate
    canvas: SVGSVGElement, // The svg canvas to draw the string on
    x: number, // The center x position of the box to draw the text relative to
    y: number, // The center y position of the box to draw the text relative to
    boxHeight: number, // The height of the box to draw the text relative to
    boxWidth: number, // The width of the box to draw the text relative to
    offset = 0, // an offset to keep the text inside the canvas
    // yPos: 'above' | 'below' | 'center' = 'below',
  ): [number, number] {
    // Get the rendered text dimensions
    const textBox = this.getDimensions(str, canvas);
    // Get the dimensions of the svg canvas
    const canvasBox = this.getBBox(canvas);
    // Calculate the center of the box
    const boxCenterX = x + 5 + boxWidth / 2;
    const boxCenterY = y + 5 + boxHeight / 2;

    // Calculate the absolute center of the text relative to the box
    let newX = boxCenterX - textBox.width / 2;
    let newY = boxCenterY + textBox.height / 2;
    // let newY = boxCenterY - textBox.height / 2;

    // Check X position
    if (newX < offset) newX = offset; // To the right of box
    if (newX + textBox.width > canvasBox.width - offset) newX = canvasBox.width - offset - textBox.width; // Left

    // Check Y position
    if (newY + textBox.height > canvasBox.height - offset) newY = boxCenterY - 8 - textBox.height; // Above box

    // Return the new position
    return [Math.abs(newX), Math.abs(newY)];
  }

  getBBox(canvas: SVGSVGElement) {
    if (canvas.hasAttribute('viewBox')) {
      const [x, y, width, height] = (canvas.getAttribute('viewBox')?.split(' ') ?? ['0', '0', '0', '0']).map(v =>
        parseFloat(v),
      );
      return { x, y, width, height };
    }
    return canvas.getBBox();
  }

  applyTransform(r: MapResource, forceRotation?: number, el?: HTMLElement): string {
    const x = el && r.textX ? r.textX : r.x;
    const y = el && r.textY ? r.textY : r.y;

    const operations = [];
    operations.push(`translate(${x}px, ${y}px)`);
    if ('flip' in r) {
      const vFlip = (r.flip?.toLowerCase().indexOf('v') ?? -1) > -1;
      const hFlip = (r.flip?.toLowerCase().indexOf('h') ?? -1) > -1;
      if ((hFlip || vFlip) && forceRotation === undefined) {
        const s = r.scale || 1;
        operations.push(`scale(${hFlip ? `-${s}` : s}, ${vFlip ? `-${s}` : s})`);
      }
    } else if (r.scale && forceRotation === undefined) {
      operations.push(`scale(${r.scale})`);
    }
    if (!el && (r.rotate || forceRotation !== undefined)) {
      const rotation = forceRotation !== undefined ? forceRotation : r.rotate;
      if (rotation != null && rotation != 0) {
        operations.push(`rotate(${rotation}deg)`);
      }
    }
    const transforms = operations.join(' ');
    return transforms;
  }

  getFilter(r: MapResource) {
    return r.type?.startsWith('sink') || r.type?.startsWith('toilet') ? '' : 'url(#drop-shadow)';
  }
}

import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { AuthService } from '@logic-suite/shared/auth';
import { RequestCache } from '@logic-suite/shared/cache';
import { createHmac } from '@logic-suite/shared/crypto/hmac';
import { MapConfig, MapService } from '@logic-suite/shared/map';
import { deepMerge, retryOn504, SharedObservable, titleCase } from '@logic-suite/shared/utils';
import { environment } from 'apps/flex/src/environments/environment';
import {
  addDays,
  addMonths,
  addSeconds,
  differenceInDays,
  endOfDay,
  getHours,
  isAfter,
  isSameDay,
  isSameYear,
  isWeekend,
  setHours,
  startOfDay,
  startOfSecond,
} from 'date-fns';
import { firstValueFrom, from, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, share, switchMap, take, tap } from 'rxjs/operators';
import { EmployeeService } from '../../views/user/profile/employee.service';
import { Employee } from '../../views/user/profile/profile.model';
import { ParkingCacheService } from '../parking/parking-cache.service';
import {
  BookWorkdaysAhead,
  Features,
  NextWorkdayStartsAt,
  PolicyService,
  PolicyType,
  WorkOnWeekends,
} from '../policy/policy.service';
import { AvailableResources, BookableResource } from '../search-dialog/search-dialog.model';
import {
  Bookable,
  BookedResourceAvailability,
  BookedResources,
  Booking,
  Entry,
  EntryDetails,
  FavoriteSort,
  FLEX,
  TimeTuple,
  UNAVAILABLE,
} from './booking.model';

@Injectable({ providedIn: 'root' })
export class BookingService {
  private map = inject(MapService);
  private http = inject(HttpClient);
  private policy = inject(PolicyService);
  private profile = inject(EmployeeService);
  private auth = inject(AuthService);
  private cache = inject(RequestCache);

  public autoBooking = signal<Entry | undefined>(undefined);
  private currentDay?: Date;
  private emp!: Employee;
  myParkings = inject(ParkingCacheService);
  private bookingUpdated = signal(0);
  bookingUpdated$ = toObservable(this.bookingUpdated) as Observable<number>;
  holidays = signal<Set<number>>(new Set());
  canBookWeekends = false;

  constructor() {
    firstValueFrom(
      this.auth.isLoggedIn$.pipe(
        filter(l => !!l),
        take(1),
        switchMap(() => this.profile.getEmployee()),
      ),
    ).then((emp: Employee) => (this.emp = emp));
    this.policy.ensurePolicyLoaded().subscribe(() => {
      this.canBookWeekends =
        ((this.policy.getPolicy(PolicyType.WorkOnWeekends) as WorkOnWeekends)?.value ?? false) == true;
    });
  }

  getHollidays(date = this.getSelectedDay()) {
    // Check if holidays for the given date's year are already cached
    if (Array.from(this.holidays()).some(h => isSameYear(h, date))) return of(Array.from(this.holidays()));

    // No holidays registered for selected year. Fetch holidays for this year.
    const accessKey = 'r0BAU0TEyj';
    const secretKey = 'B15tspjr9ccK7HBWOG5x';
    const expires = new Date();

    return from(createHmac(secretKey, `${accessKey}holidays${expires.toISOString()}`)).pipe(
      switchMap(signature =>
        environment.production
          ? this.http.get(`/time/holidays`, {
              params: {
                version: '3',
                accesskey: accessKey,
                signature: signature,
                timestamp: expires.toISOString(),
                country: 'no',
                lang: 'no',
                year: date.getFullYear().toString(),
                types: 'federal,federallocal,religious',
              },
            })
          : of({ holidays: [] }),
      ),
      map((res: any) => {
        if ('errors' in res) throw new Error(JSON.stringify(res.errors));
        // Add holidays to cache
        this.holidays.set(
          new Set(
            [...Array.from(this.holidays()), ...res.holidays.map((h: any) => new Date(h.date.iso).getTime())].sort(),
          ),
        );
        return Array.from(this.holidays());
      }),
    );
  }

  private mapColors(res: Bookable[]): Bookable[] {
    return res.map(r => this.addColor(r));
  }

  addColor(r: Bookable): Bookable {
    if (!r) return r;
    r.icon = r.icon || 'office';
    r.resourceNameAlias = r.resourceNameAlias || r.nameAlias || (r as Booking).resourceName || (r as Entry).name;
    // if (r.color) return r;
    if (r.category) {
      // prettier-ignore
      switch (r.category) {
        case FLEX: r.color = 'grad2'; break;
        case UNAVAILABLE: r.color = 'grad5'; break;
        case 'office': r.color = 'grad1'; break;
        case 'coworking': r.color = 'grad3'; break;
      }
    } else {
      // prettier-ignore
      switch(r.icon) {
        case 'office': r.color = 'grad1'; break;
        case FLEX:   r.color = 'grad2'; break;
        case UNAVAILABLE: r.color = 'grad5'; break;
        case 'mesh':   r.color = 'grad3'; break;
        case 'target': r.color = 'grad4'; break;
      }
    }
    if ('entryType' in r && 'category' in r && (r as Entry).identifier == null) {
      (r as Entry).identifier = `${(r as Entry).category}${(r as Entry).entryType}${(r as Entry).entryID}`;
    }
    if (
      this.autoBooking()?.entryID != null &&
      (this.autoBooking()?.entryID === (r as Entry).entryID ||
        this.autoBooking()?.entryID === (r as Booking).resourceID)
    ) {
      r.category = 'auto';
      r.icon = 'autoBooking';
      r.color = 'grad12';
    }
    return r;
  }

  registerSubscription(sub: PushSubscription): Observable<any> {
    return this.http.post<any>(`/api/flex/PushNotification`, sub);
  }

  flatMap(mapID: number, markResourceID: number) {
    return this.http.get<MapConfig>(`/api/flex/Map/${mapID}`).pipe(
      switchMap(config => this.map.flatMap(config, markResourceID)),
      map(map => URL.createObjectURL(map)),
    );
  }

  /**
   *
   * @returns The current day as set in date-selector
   */
  getSelectedDay(): Date {
    if (this.currentDay) return this.currentDay;
    return this.getToday();
  }

  setSelectedDay(date?: Date) {
    if (date && isSameDay(date, this.getToday())) date = undefined;
    this.currentDay = date;
  }

  /**
   *
   * @returns The current day as defined in policy based on todays date.
   */
  getToday() {
    const now = Date.now();
    const nextDayStartsAt = this.policy.getPolicy(PolicyType.NextWorkdayStartsAt) as NextWorkdayStartsAt;
    const reference = setHours(startOfDay(now), nextDayStartsAt?.value ?? 17);
    // After 17:00, we should be scheduling next day.
    return isAfter(now, reference) ? startOfDay(addDays(now, 1)) : startOfDay(now);
  }

  getMaxBookingDate(entry?: Entry) {
    let maxDay = addMonths(new Date(), 3); // Default allow 3 months ahead in time
    const startDate = startOfDay(this.getToday()); // Either today or if policy dictates it, tomorrow
    const bookWorkdays = this.policy.getPolicy(PolicyType.BookWorkdaysAhead) as BookWorkdaysAhead[];

    if (bookWorkdays) {
      const defaultPolicy = bookWorkdays.find(b => !('entryID' in b) || b.entryID == null);
      let policy = defaultPolicy;
      // If entry has a policy override, use it. Otherwise, use default.
      if (entry != null) {
        // Find the policy matching this entry
        policy = bookWorkdays.find(p => p.entryID === '' + entry.zoneID) || bookWorkdays[0];
      } else {
        // No entry given, find the policy with the largest amongst the policies in my neighborhoods
        const myPolicies = bookWorkdays.filter(b => this.emp.zoneIDs?.includes(+(b.entryID || -1)));
        if (myPolicies.length) {
          const max = Math.max(
            ...myPolicies.map(p => p.allocatedBookDaysAhead || -1),
            ...bookWorkdays.map(p => p.bookDaysAhead || -1),
            defaultPolicy?.bookDaysAhead ?? -1,
          );
          policy = myPolicies.find(p => p.allocatedBookDaysAhead === max) || defaultPolicy;
        } else {
          const max = Math.max(...bookWorkdays.map(p => p.bookDaysAhead || -1), defaultPolicy?.bookDaysAhead ?? -1);
          policy = bookWorkdays.find(p => p.bookDaysAhead === max) || defaultPolicy;
        }
      }

      // If entry is in users neighborhood, use `allocatedBookDaysAhead`. If not, use `bookDaysAhead`.
      // If no policy is set, or if any of these properties are missing in the current policy for this entry,
      // use `bookDaysAhead` from default policy.
      const isUserInNeighborhood = this.emp.zoneIDs?.includes(+(policy?.entryID || -1)) ?? false;
      // Default policy used
      let allowDaysAhead = defaultPolicy?.bookDaysAhead || 90;
      if (isUserInNeighborhood && policy?.allocatedBookDaysAhead != null) {
        // Special privileges for user in own neighborhoods
        allowDaysAhead = policy.allocatedBookDaysAhead;
      } else if (!isUserInNeighborhood && policy?.bookDaysAhead != null) {
        // Policy for everyone else in this neighborhood
        allowDaysAhead = policy.bookDaysAhead as number;
      }

      // Add base number of days
      maxDay = addDays(startDate, allowDaysAhead);

      // Add weekends if policy says weekend days are *not* workdays
      if (this.canBookWeekends != true) {
        // Does not allow work on weekends. Add days to maxDay if we hit a weekend in the near future.
        let i = 0;
        while (i <= allowDaysAhead) {
          if (isWeekend(addDays(startDate, i))) {
            // Next day is weekend. Add one more day to stack.
            maxDay = addDays(maxDay, 1);
            allowDaysAhead++;
          }
          i++;
        }
      }
    }
    return addSeconds(maxDay, 1);
  }

  bookingToGroup(b: Booking): Entry {
    const entry = this.addColor({
      name: b.resourceName,
      assetName: b.assetName,
      levelName: b.levelName,
      resourceNameAlias: b.resourceNameAlias,
      icon: b.icon || '',
      category: b.category || '',
      color: b.color,
      favorite: b.favorite || b.storeAsFavorite || false,
      entryType: 'resource',
      entryID: b.resourceID,
      parentEntryType: b.entryType,
      parentEntryID: b.entryID,
      // fromMs: b.startTimeMs || b.fromMs,
      // toMs: b.endTimeMs || b.toMs,
      isAutoBooking: b.storeAsAutoBooking === true || b.isAutoBooking === true,
    } as unknown as Bookable) as Entry;
    return entry as Entry;
  }

  groupToBooking(g: Entry | Booking): Booking {
    const booking = {
      bookingID: (g as Booking).bookingID || undefined,
      resourceID: ((g as Booking).resourceID || (g.entryType == 'resource' ? g.entryID : undefined)) as number,
      resourceName: ((g as Booking).resourceName || (g as Entry).name || undefined) as string,
      resourceNameAlias: (g.nameAlias || g.resourceNameAlias) as string,
      assetName: (g as Booking).assetName as string,
      category: g.category,
      color: g.color,
      icon: g.icon,
      fromMs: g.fromMs as number,
      toMs: g.toMs as number,
      withLunch: (g as Booking).withLunch || false,
      withParking: (g as Booking).withParking || false,
      storeAsFavorite: (g as Booking).storeAsFavorite || g.favorite || false,
      storeAsQuickBooking: (g as Booking).storeAsQuickBooking || (g as Entry).quickBooking || false,
      storeAsAutoBooking: (g as Booking).storeAsAutoBooking || (g as Entry).isAutoBooking || false,
      isAutoBooking: g.isAutoBooking || false,
      sectionName: (g as any).sectionName as string,
      levelID: ((g as Entry).parentEntryType === 'level' ? (g as Entry).parentEntryID : undefined) as number,
      levelName: (g as Entry).levelName as string,
      zoneName: (g as Entry).zoneName as string,
      entryType: g.entryType,
      entryID: g.entryID,
      mapID: g.mapID as number,
      assetImageUrl: (g as any).assetImageUrl as string,
    };
    return booking as Booking;
  }

  groupToMapResource(entry: Entry): BookableResource {
    const resource = {
      resourceID: entry.entryID,
      resourceName: entry.name,
      assetName: entry.assetName,
      levelName: entry.levelName,
      resourceNameAlias: entry.resourceNameAlias,
      type: entry.entryType,
      category: entry.category,
      x: -1,
      y: -1,
      unavailable: false,
      selected: false,
    } as BookableResource;
    return deepMerge(structuredClone(entry), resource) as BookableResource;
  }

  getCurrentTimeTuple(tuple?: TimeTuple): TimeTuple {
    return {
      fromMs: tuple?.fromMs || startOfDay(this.getSelectedDay()).getTime(),
      toMs: tuple?.toMs || startOfSecond(endOfDay(this.getSelectedDay())).getTime(),
    };
  }

  /**
   * Get a list of <code>Bookings</code> for a particular date range
   */
  @SharedObservable({ emitOnce: true })
  getBookings(fromMs?: number, toMs?: number, employeeID?: number): Observable<Booking[]> {
    const tuple = this.getCurrentTimeTuple({ fromMs, toMs } as TimeTuple);
    return this.http
      .get<Booking[]>(`/api/flex/Booking`, {
        params: {
          fromMs: '' + tuple.fromMs,
          toMs: '' + tuple.toMs,
          ...(employeeID ? { employeeID: '' + employeeID } : {}),
        },
      })
      .pipe(
        retryOn504(),
        map(r => this.mapColors(r) as Booking[]),
        map(r =>
          r.map(i => {
            i.withLunch = true;
            return i;
          }),
        ),
      );
  }

  async getHoursBooked(date: Date, resourceID?: number) {
    if (date) {
      const bookings = await firstValueFrom(
        this.getBookings(startOfDay(date).getTime(), startOfSecond(endOfDay(date)).getTime()),
      );
      return this.toHoursBooked(bookings, resourceID);
    }
    return [] as number[];
  }

  toHoursBooked(bookings: Booking[], resourceID?: number) {
    return (
      bookings
        ?.filter(b => resourceID == null || b.resourceID == resourceID)
        .reduce((acc, t) => {
          // Convert timestamps to hour strings
          const from = getHours(t.fromMs);
          const to = getHours(t.toMs);
          for (let i = from; i <= to; i++) {
            acc.push(i);
          }
          return acc;
        }, [] as number[]) ?? []
    );
  }

  /**
   * Get a resource configured as a "quick booking"
   */
  @SharedObservable()
  getQuickBooking(
    fromMs = startOfDay(new Date()).getTime(),
    toMs = startOfSecond(endOfDay(new Date())).getTime(),
  ): Observable<Entry> {
    toMs = startOfSecond(toMs).getTime();
    return this.http
      .get<Entry>(`/api/flex/QuickBooking`, {
        params: {
          fromMs: '' + fromMs,
          toMs: '' + toMs,
        },
      })
      .pipe(
        retryOn504(),
        map((res: Entry) => this.addColor(res) as Entry),
      );
  }

  /**
   * Get the resource configured as an "auto booking"
   *
   * @returns The resource configured as an "auto booking", or <code>{entryID: -1}</code>
   * if no auto booking is configured. If the request fails for any reason, <code>null</code> is returned.
   */
  @SharedObservable()
  getAutoBooking(fromMs = startOfDay(new Date()).getTime(), toMs = startOfSecond(endOfDay(new Date())).getTime()) {
    return this.http
      .get<Entry>(`/api/flex/QuickBooking/AutoBooking`, {
        params: {
          fromMs: '' + fromMs,
          toMs: '' + toMs,
        },
      })
      .pipe(
        retryOn504(),
        map((res: Entry) => {
          if (res) {
            this.autoBooking.set(res);
            this.addColor(res);
            return this.autoBooking() as Entry;
          }
          this.autoBooking.set(undefined);
          return { entryID: -1 } as Entry;
        }),
        catchError(() => of(null as unknown as Entry)),
      );
  }

  /**
   * Removes the resource from the auto-booking system. This will
   * stop recurring bookings from being made, but it will not delete
   * bookings already done.
   */
  removeAutoBooking() {
    return this.http.delete(`/api/flex/QuickBooking/AutoBooking`).pipe(
      tap(() => {
        this.autoBooking.set(undefined);
        this.cache.invalidate(`/api/flex/QuickBooking`);
        this.cache.invalidate(`/api/flex/Booking`);
        this.cache.invalidate(`/api/flex/Entry`);
      }),
    );
  }

  /**
   * Get any AssetTree object user has specified as a favorite,
   * or use the `filter` to limit the AssetTree objects to specific
   * types. I.E. Only "resource" types.
   */
  @SharedObservable()
  getFavorites(
    fromMs = startOfDay(new Date()).getTime(),
    toMs = endOfDay(new Date()).getTime(),
    filter?: string,
  ): Observable<Entry[]> {
    toMs = startOfSecond(toMs).getTime();
    return this.http
      .get<Entry[]>(`/api/flex/Favorite`, {
        params: {
          fromMs: '' + fromMs,
          toMs: '' + toMs,
          ...(!!filter && { entryTypeFilter: filter }),
        },
      })
      .pipe(
        retryOn504(),
        switchMap((res: Entry[]) => this.policy.ensurePolicyLoaded().pipe(map(() => res))),
        map((res: Entry[]) => {
          if (this.policy.hasFeature(Features.Unavailable) && res.findIndex(r => r.icon === UNAVAILABLE) < 0) {
            res.unshift({
              name: titleCase(UNAVAILABLE),
              icon: UNAVAILABLE,
              category: UNAVAILABLE,
              entryType: 'resource',
              favoriteSortOrder: -1,
              totalResourceCount: 1,
              usedResourceCount: 0,
              quickBooking: true,
            } as Entry);
          }
          if (res.findIndex(r => r.icon === FLEX) < 0) {
            res.unshift({
              name: titleCase(FLEX),
              icon: FLEX,
              category: FLEX,
              entryType: 'resource',
              favoriteSortOrder: -1,
              totalResourceCount: 1,
              usedResourceCount: 0,
              quickBooking: true,
            } as Entry);
          }
          const sortOrder: any = { [FLEX]: 1, [UNAVAILABLE]: 2, default: 100 };
          const typeOrder: any = {
            assetGroup: 2,
            asset: 3,
            section: 4,
            level: 5,
            zone: 6,
            room: 7,
            resource: 8,
            default: 1,
          };

          return (this.mapColors(res) as Entry[]).sort((a: Entry, b: Entry): number => {
            const aOrder = sortOrder[a.category] || sortOrder['default'] + (a.favoriteSortOrder || 0);
            const bOrder = sortOrder[b.category] || sortOrder['default'] + (b.favoriteSortOrder || 0);
            // Flex and Unavailable should always be first
            if (aOrder - bOrder != 0) return aOrder - bOrder;
            // After that, the user should define the order of the favorites
            if (a.favoriteSortOrder && b.favoriteSortOrder) return a.favoriteSortOrder - b.favoriteSortOrder;
            // If no order is set, sort by entryType where each entryType is alphabetically ordered.
            if (a.entryType === b.entryType) return a.name > b.name ? 1 : -1;
            const aType = typeOrder[a.entryType] || typeOrder['default'];
            const bType = typeOrder[b.entryType] || typeOrder['default'];
            return aType - bType;
          });
        }),
      );
  }

  getFavoriteDetails(group: Entry): Observable<EntryDetails> {
    if (!group) return throwError(() => 'No entryType or entryID given');
    return this.http.get<EntryDetails>(`/api/flex/Favorite/${group.entryType}/${group.entryID}`).pipe(
      retryOn504(),
      map(details => this.addColor(details) as EntryDetails),
      map(details => {
        details.resourceName = details.originalName;
        return details;
      }),
    );
  }

  setFavoriteSortOrder(favorites: FavoriteSort[]): Observable<FavoriteSort[]> {
    return this.http
      .post<FavoriteSort[]>(`/api/flex/Favorite/SortOrder`, favorites)
      .pipe(tap(() => this.cache.invalidate(`/api/flex/Favorite`)));
  }

  setFavoriteAlias(fav: EntryDetails): Observable<boolean> {
    return this.http
      .post<boolean>(`/api/flex/Favorite/NameAlias`, {
        entryType: fav.entryType,
        entryID: fav.entryID,
        name: fav.resourceNameAlias,
      })
      .pipe(tap(() => this.cache.invalidate(`/api/flex/Favorite`)));
  }

  setFavoriteIcon(fav: EntryDetails): Observable<boolean> {
    return this.http
      .post<boolean>(`/api/flex/Favorite/Icon`, {
        entryType: fav.entryType,
        entryID: fav.entryID,
        icon: fav.icon,
      })
      .pipe(
        tap(() => {
          this.cache.invalidate(`/api/flex/Favorite`);
          this.cache.invalidate(`/api/flex/QuickBooking`);
        }),
      );
  }

  private toTimeString(times: TimeTuple[]) {
    return times.map(t => `${t.fromMs}-${t.toMs}`);
  }

  formatHour(hour: number) {
    return hour < 10 ? `0${hour}` : `${hour}`;
  }

  timeTupleToHours(times: TimeTuple[]) {
    return (
      times.reduce((acc, t) => {
        const hourFrom = getHours(t.fromMs);
        const hourTo = getHours(t.toMs);
        for (let i = hourFrom; i <= hourTo; i++) {
          acc.push(i);
        }
        return acc;
      }, [] as number[]) ?? []
    );
  }

  timeTupleToHourString(times: TimeTuple[]) {
    return this.timeTupleToHours(times).map(t => this.formatHour(t));
  }

  /**
   * Get
   */
  @SharedObservable()
  getEntries(entry = 'root', times: TimeTuple[], id?: number): Observable<Entry[]> {
    return this.http
      .get<Entry[]>(`/api/flex/Entry`, {
        params: {
          timeMs: this.toTimeString(times),
          entryType: entry,
          ...(id && { entryID: id }),
        },
      })
      .pipe(
        retryOn504(),
        share(),
        map((res: Entry[]) => this.mapColors(res) as Entry[]),
      );
  }

  toggleFavorite(entryType: string, entryID: number, flag: boolean): Observable<boolean> {
    return this.http
      .post<boolean>(`/api/flex/Favorite`, {
        entryType,
        entryID,
        favorite: flag,
      })
      .pipe(
        tap(() => {
          this.cache.invalidate(`/api/flex/Favorite`);
          this.cache.invalidate(`/api/flex/Team`);
        }),
      );
  }

  @SharedObservable()
  getBookedResources(resourceID: number, fromMs: number, toMs: number): Observable<BookedResources[]> {
    if (!resourceID) return throwError(() => 'No resourceID given');
    return this.http
      .get<BookedResources[]>(`/api/flex/Booking/${resourceID}`, {
        params: {
          fromMs: '' + fromMs,
          toMs: '' + toMs,
        },
      })
      .pipe(retryOn504());
  }

  @SharedObservable()
  getBookedResourceAvailability(resourceIDs: number[], fromMs: number, toMs: number) {
    return this.http
      .get<BookedResourceAvailability[]>(`/api/flex/Booking/Resources`, {
        params: { resourceID: resourceIDs, fromMs, toMs },
      })
      .pipe(retryOn504());
  }

  @SharedObservable()
  getResourceAvailability(time: TimeTuple[], entryType?: string, entryID?: number): Observable<AvailableResources[]> {
    return this.http
      .get<AvailableResources[]>(`/api/flex/Entry/Resource`, {
        params: {
          timeMs: this.toTimeString(time),
          ...(entryType && { entryType }),
          ...(entryID && { entryID }),
        },
      })
      .pipe(
        retryOn504(),
        map((res: AvailableResources[]) => {
          return (structuredClone(res) as AvailableResources[]).map(curr => {
            const maxDay = this.getMaxBookingDate(this.bookingToGroup(JSON.parse(JSON.stringify(curr)) as Booking));
            const now = this.getSelectedDay();
            const weekend = time.some(t => isWeekend(t.fromMs) || isWeekend(t.toMs));
            if ((!this.canBookWeekends && weekend) || (maxDay != null && isAfter(now, maxDay))) {
              curr.occupied = Array.from(new Array(24)).map((_, i) => i);
            }
            return this.addColor(curr);
          }) as AvailableResources[];
        }),
      );
  }

  bookResource(selectedSeat: Booking[]): Observable<Booking[]> {
    selectedSeat.forEach(b => (b.bookingID = undefined));

    // Normal booking by default
    let doBooking$ = this.http.post<Booking[]>(`/api/flex/Booking`, selectedSeat);

    // But if the given input contains `storeAsAutoBooking`...
    if (selectedSeat.every(b => b.storeAsAutoBooking === true)) {
      // ... we need to extend the booking to contain the entire booking horizon
      // for this seat, excluding days where user has booked something else.
      const maxDay = this.getMaxBookingDate(this.bookingToGroup(selectedSeat[0]));
      const fromMs = selectedSeat[0].fromMs;
      const toMs = startOfSecond(endOfDay(maxDay || selectedSeat[0].toMs)).getTime();

      let autoBookings: Booking[] = [...new Array(differenceInDays(toMs, fromMs))].reduce(
        (acc, c, idx) => [
          ...acc,
          Object.assign(structuredClone(selectedSeat[0]), {
            fromMs: addDays(fromMs, idx).getTime(),
            toMs: addDays(startOfSecond(endOfDay(fromMs)), idx).getTime(),
          }) as Booking,
        ],
        [],
      );
      doBooking$ = this.getBookings(fromMs, toMs).pipe(
        switchMap(bookings => {
          autoBookings = autoBookings.filter(day => {
            return (
              // Remove days from autoBookings where user has already booked something
              bookings.find(bk => isSameDay(bk.fromMs, day.fromMs) && bk.isAutoBooking !== true) == null &&
              // Remove days from autoBookings where day is a holiday
              !Array.from(this.holidays()).some(h => isSameDay(h, day.fromMs)) &&
              // Remove days from autoBookings where day is a weekend and user is not allowed to book weekends
              (this.canBookWeekends || !isWeekend(day.fromMs))
            );
          });
          return this.http.post<Booking[]>(`/api/flex/Booking`, autoBookings);
        }),
      );
    }

    // Do the work
    return doBooking$.pipe(
      tap(res => {
        res.forEach(booking => {
          if ('employeeParking' in booking && booking.employeeParking != null) {
            this.myParkings.set(booking.employeeParking.dateMs, booking.employeeParking);
          }
          if (
            this.autoBooking()?.entryID !== booking.resourceID &&
            (booking.storeAsAutoBooking === true || booking.isAutoBooking === true)
          ) {
            this.autoBooking.set(this.bookingToGroup(booking));
          }
          this.addColor(booking);
        });
        this.cache.invalidate(`/api/flex/Booking`);
        this.cache.invalidate(`/api/flex/QuickBooking`);
        this.cache.invalidate(`/api/flex/Favorite`);
        this.cache.invalidate(`/api/flex/Transport`);
        this.cache.invalidate(`/api/flex/Entry`);
        this.cache.invalidate(`/api/flex/Team`);
        this.cache.invalidate(`/api/flex/MeetingRoom`);
        this.cache.invalidate(`/api/flex/EmployeeParking`);
        this.bookingUpdated.set(this.bookingUpdated() + 1);
      }),
    );
  }

  removeBookings(bookings: Booking[]) {
    if (!bookings.length) return throwError(() => 'No bookingID given');
    return this.http
      .delete(`/api/flex/Booking`, {
        responseType: 'text',
        params: {
          bookingIDs: bookings.map(b => '' + b.bookingID),
        },
      })
      .pipe(
        tap(() => {
          this.cache.invalidate(`/api/flex/Booking`);
          this.cache.invalidate(`/api/flex/QuickBooking`);
          this.cache.invalidate(`/api/flex/Favorite`);
          this.cache.invalidate(`/api/flex/Transport`);
          this.cache.invalidate(`/api/flex/Entry`);
          this.cache.invalidate(`/api/flex/Team`);
          this.cache.invalidate(`/api/flex/MeetingRoom`);
          this.cache.invalidate(`/api/flex/EmployeeParking`);
          this.bookingUpdated.set(this.bookingUpdated() + 1);
        }),
        map((res: string) => true),
      );
  }

  compileOccupied(fromMs: number, toMs: number) {
    const from = getHours(fromMs);
    const to = getHours(toMs);
    const occupied = [];
    for (let j = from; j <= to; j++) {
      occupied.push(j);
    }
    return occupied;
  }

  showColleagues(mapId: number, fromMs?: number, toMs?: number) {
    if (!mapId) return throwError(() => 'No mapID given');
    return this.http.post<boolean>(`/api/flex/Map/${mapId}/ShowColleagues`, {
      ...(fromMs && { fromMs }),
      ...(toMs && { toMs }),
    });
  }

  getNeighborhoods(times: TimeTuple[]): Observable<Entry[]> {
    return this.http
      .get<Entry[]>(`/api/flex/Neighborhood`, {
        params: { timeMs: this.toTimeString(times) },
      })
      .pipe(
        share(),
        map((res: Entry[]) => this.mapColors(res) as Entry[]),
      );
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  signal,
} from '@angular/core';
import { resetZoom } from '@logic-suite/shared/utils/resetZoom';
import { FullscreenCanvas, Viewport } from '../../map';

export enum ViewState {
  FULLSCREEN,
  NORMAL,
}
@Component({
  selector: 'lib-fullscreen',
  templateUrl: './fullscreen.component.html',
  styleUrls: ['./fullscreen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullscreenComponent implements AfterViewInit {
  @HostBinding('class.fullscreen')
  get _isFullscreen() {
    return this.showFullscreen();
  }

  showFullscreen = signal(false);
  lastTouch?: number;
  lastToggled?: number;
  __created = performance.now();
  startPointers = 0;
  startPos = { x: 0, y: 0 };

  @Input() disableFullscreen = false;
  @Input() view?: FullscreenCanvas;
  @Output() viewPortChanged = new EventEmitter<Viewport>();
  @Output() stateChanged = new EventEmitter<ViewState>();

  constructor(public el: ElementRef) {}

  ngAfterViewInit() {
    this.stateChanged.emit(ViewState.NORMAL);
    this.onScroll({ target: this.el.nativeElement } as Event);
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart(evt: TouchEvent) {
    // It's only a tap if it is with one finger. Check if 1 finger is down
    this.startPointers = evt.touches.length;
    this.startPos = { x: evt.touches[0].clientX, y: evt.touches[0].clientX };
  }

  @HostListener('touchend', ['$event'])
  onTouchEnd(evt: TouchEvent) {
    // It's only a tap if it is with one finger. Check if 0 fingers are down
    // and that the start position is nearly the same as the end position
    const deltaX = Math.abs(evt.touches[0].clientX - this.startPos.x);
    const deltaY = Math.abs(evt.touches[0].clientY - this.startPos.y);
    const hasMoved = deltaX > 10 || deltaY > 10;
    if (evt.touches.length === 0 && this.startPointers === 1 && !hasMoved) {
      // It's only a doubletap if last touch was less than 500ms ago
      if (this.lastTouch && performance.now() - this.lastTouch < 500) {
        this.lastTouch = undefined;
        return this.toggleFullscreen();
      } else {
        this.lastTouch = performance.now();
      }
    }
  }

  @HostListener('dblclick')
  dblClick() {
    if (performance.now() - this.__created > 500) {
      this.toggleFullscreen();
    }
  }

  toggleFullscreen() {
    if (this.disableFullscreen === true) return;

    if (!this.lastToggled || performance.now() - this.lastToggled > 500) {
      if (this.showFullscreen()) {
        this.closeFullscreen();
      } else {
        this.showFullscreen.set(true);
        this.stateChanged.emit(ViewState.FULLSCREEN);
      }
      this.lastToggled = performance.now();
    }
  }

  @HostListener('keydown.escape', ['$event'])
  closeFullscreen($event?: KeyboardEvent) {
    if (this.disableFullscreen === true || !this.showFullscreen()) return;
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    resetZoom();

    // Reset the fullscreen state
    this.showFullscreen.set(false);
    this.stateChanged.emit(ViewState.NORMAL);
  }

  @HostListener('scroll', ['$event'])
  onScroll(evt: Event) {
    // Die quickly if noone is listening
    if (!this.viewPortChanged.observers.length) return;

    // Calculate the current scroll position of the viewport
    // so that we can update the rect on the minimaps accordingly
    const el = evt.target as HTMLElement;
    const atStart = el.scrollLeft == 0 && el.scrollTop == 0;
    const from = atStart
      ? { x: 0, y: 0 }
      : this.view?.toPoint(el.scrollLeft / this.view.zoom, el.scrollTop / this.view.zoom);
    const to = this.view?.toPoint(
      (el.scrollLeft + el.clientWidth) / this.view.zoom,
      (el.scrollTop + el.clientHeight) / this.view.zoom,
    );
    if (from && to) {
      const viewport = { x: from.x, y: from.y, width: to.x - from.x, height: to.y - from.y };
      this.viewPortChanged.emit(viewport);
    }
  }
}

export function resetZoom() {
  // Hack to reset pinch zoom
  const viewport = document.querySelector('meta[name="viewport"]');
  if (viewport) {
    viewport.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0');
    setTimeout(() => {
      viewport.setAttribute(
        'content',
        'width=device-width, height=device-height, initial-scale=1.0, viewport-fit=cover',
      );
    }, 100);
  }
}

export async function createHmac(secretKey: string, data: string, method = 'SHA-1') {
  const enc = new TextEncoder();

  const key = await crypto.subtle.importKey(
    'raw', // raw format of the key - should be Uint8Array
    enc.encode(secretKey),
    { name: 'HMAC', hash: { name: method } },
    false, // export = false
    ['sign', 'verify'], // what this key can do
  );
  const signature = await crypto.subtle.sign('HMAC', key, enc.encode(data));
  return btoa(String.fromCharCode(...new Uint8Array(signature)));
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AutofocusDirective } from './autofocus/autofocus.directive';
import { ContainerResizeDirective } from './container-resize.directive';
import { InlineSvgDirective } from './inline-svg.directive';
import { LongPressDirective } from './long-press.directive';
import { DelayedRouterLinkWithHrefDirective } from './router-link.directive';

@NgModule({
  declarations: [ContainerResizeDirective, InlineSvgDirective, DelayedRouterLinkWithHrefDirective, LongPressDirective],
  imports: [CommonModule, AutofocusDirective],
  exports: [
    ContainerResizeDirective,
    AutofocusDirective,
    InlineSvgDirective,
    DelayedRouterLinkWithHrefDirective,
    LongPressDirective,
  ],
})
export class DirectivesModule {}

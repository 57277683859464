import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullscreenComponent } from './fullscreen.component';
import { DirectivesModule } from '../../directives';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [FullscreenComponent],
  imports: [CommonModule, DirectivesModule, MatButtonModule, MatIconModule],
  exports: [FullscreenComponent],
})
export class FullscreenModule {}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  computed,
  effect,
  inject,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import DOMPurify from 'dompurify';
import { Observable, ReplaySubject, Subscription, firstValueFrom } from 'rxjs';

import { ViewState } from '../components/fullscreen';
import { Debouncer as Denounced, getAlphaValue, getEnv, logger, waitForElement } from '../utils';
import { resetZoom } from '../utils/resetZoom';
import { ScrollPosition, scrollIntoView } from '../utils/scrollIntoView';
import { abbreviate, underscore } from '../utils/stringUtils';
import {
  ColleagueBookings,
  FullscreenCanvas,
  MapConfig,
  MapDoorElement,
  MapLabelElement,
  MapResource,
  MapSymbolElement,
  MapZoneElement,
  Neighborhood,
  Point,
  ResourceCheckCallback,
  Viewport,
  ZoneSelection,
} from './map.model';
import { MapService, VIEWBOX_REGEX } from './map.service';
import { IMapSymbol } from './symbols';

const debug = false;

@Component({
  selector: 'lib-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent implements OnChanges, AfterViewInit, OnDestroy, FullscreenCanvas {
  private map = inject(MapService);
  private sec = inject(DomSanitizer);
  public rootElement = inject(ElementRef);
  private snack = inject(MatSnackBar);
  private cdr = inject(ChangeDetectorRef);
  private ngZone = inject(NgZone);

  subscriptions: Subscription[] = [];

  @HostBinding('class.displayed') display = false;
  @HostBinding('class.fullscreen') showFullscreen = false;
  @HostBinding('class.resourceSelection') canSelectResource = true;
  @HostBinding('class.zoomed') isZoomed = false;

  /** This is true for maps which require user interaction. The map-search in booking dialog is a good example */
  @HostBinding('class.interactive') @Input() interactive = false;
  @HostBinding('class') @Input() type = '';

  @Input() isAvailable?: ResourceCheckCallback;
  @Input() isFavorite?: ResourceCheckCallback;
  @Input() viewport?: Viewport; // For thumbnail map
  @Input() viewStateChange?: Observable<ViewState>;

  visibleLayers = input<string[]>(['zones', 'icons', 'resources', 'doors']);
  showZones = input(true, { transform: v => v === true });
  focusOn = input<'neighborhood' | 'all' | 'youAreHere'>('all');
  selectableTypes = input([
    'workstation',
    'workstation_singleMonitor',
    'workstation_dualMonitor',
    'double_workstation',
  ]);

  selectionMade = output<MapResource>();
  zoneSelectionMade = output<ZoneSelection>();
  mapLoaded = output<MapConfig>();
  mapRendered = output<boolean>();

  rendered = false;
  private _zoom = 1;
  get zoom() {
    return this._zoom;
  }
  set zoom(n: number) {
    this._zoom = n;
    this.rootElement.nativeElement.setAttribute('style', `transform: scale(${this.zoom})`);
  }

  useHoodColorOnDesks = computed(() => {
    return this._internalConfig()?.showNeighborhoodColors != null
      ? this._internalConfig()!.showNeighborhoodColors
      : getEnv('showHoodColors');
  });
  // For marking where your spot is
  resourceID = input<number | number[] | undefined>(undefined);
  // For marking colleague spot
  markResourceID = input<number | undefined>(undefined);
  markResource = computed(() => this.resources().find(r => r.resourceID === this.markResourceID()));
  markResourceName = input('', { transform: v => (v ? v : '') }); // Naming colleague spot

  colleagues = input<ColleagueBookings[]>([]);

  enableZoom = input(null, { transform: v => v === true });
  _zoomEnabled = computed(() => {
    return (
      this._internalConfig() != null &&
      this.resources().length > 0 &&
      (this.enableZoom() != null ? (this.enableZoom() as boolean) : this._internalConfig()!.enableZoom === true)
    );
  });

  mapLoaded$ = new ReplaySubject(1);

  // Process map configuration
  configUrl = input('/api/flex/Map');
  mapID = input<number | undefined>(undefined);
  myZoneID = input<number[] | undefined>(undefined);
  config = input<MapConfig | undefined>(undefined);
  _internalConfig = signal<MapConfig | undefined>(undefined);
  floorPlanSVG = computed<SafeHtml | undefined>(() => {
    if (this._internalConfig()?.floorPlanSVG == null) return undefined;
    const value = (this._internalConfig()?.floorPlanSVG ?? '').replace(VIEWBOX_REGEX, '');
    return this.sec.bypassSecurityTrustHtml(
      DOMPurify.sanitize(value, {
        USE_PROFILES: { svg: true, svgFilters: true },
      }),
    );
  });
  coordinates = computed<string>(() =>
    this._internalConfig() == null ? '0 0 0 0' : this.map.getCoordinates(this._internalConfig()!),
  );
  // All the symbols used
  symbols = signal<IMapSymbol[]>(this.map.symbols);
  // All icons
  resources = computed<MapSymbolElement[]>(() => {
    const config = this._internalConfig();
    const useHoodColorOnDesks = this.useHoodColorOnDesks();
    return config == null || this.coordinates() == '0 0 0 0'
      ? []
      : (config!.resources.filter(r => !['zone', 'icon', 'door', 'label'].some(t => r.type?.startsWith(t))) ?? []).map(
          resource => {
            const r = resource as MapSymbolElement;
            const symbol = this.map.symbols.find(s => s.id === r.type);
            r.width = symbol?.width ?? 2;
            r.height = symbol?.height ?? 2;
            r.layer = 'resources';
            r.transform = this.map.applyTransform(r);
            r.style = `transform: ${r.transform}; transform-origin: ${r.width! / 2}px ${r.height! / 2}px;`;
            r.filter = this.type != 'minimap' ? this.map.getFilter(r) : '';
            r.available = this.type != 'minimap' && this._isAvailable(r);
            r.isFavorite = this.type != 'minimap' && this._isFavorite(r);
            if (useHoodColorOnDesks && r.zoneID) {
              const hood = this.neighborhoods().find(n => n.zoneID === r.zoneID);
              if (hood) {
                r.hoodColor = hood.colorCode;
                r.style = `${r.style} ${`--hoodColor: ${r.hoodColor}; --hoodColorAlpha: ${hood.alpha}%;`}`;
              }
            }
            r.classList = Object.entries({
              [r.type]: true,
              // Seat available means that nobody is occupying it at the moment
              available:
                r.available !== false &&
                r.unavailable !== true &&
                !(this.selectableTypes().includes(r.type) && !r.resourceID),
              // Blocked seats cannot be booked
              blocked: r.unavailable === true || (this.selectableTypes().includes(r.type) && !r.resourceID),
              favorite: r.isFavorite,
              selected: r.selected,
              booked: r.state?.booked,
              partlyBooked: r.available === 'partly',
              occupied: r.state?.type === 'aggregated' && +(r.state?.value ?? 0) > 0,
              you: this.resourceID() != null && r.resourceID != null && r.resourceID === this.resourceID(),
            })
              .filter(([k, v]) => v == true)
              .map(([k, v]) => k);
            r.resourceID != null && r.classList.push('resourceID_' + r.resourceID);
            return r;
          },
        );
  });
  selectableResources = computed(() => this.resources().filter(r => r.resourceName != null));
  icons = computed<MapSymbolElement[]>(() =>
    this._internalConfig() == null || this.type == 'minimap'
      ? []
      : (this._internalConfig()!.resources.filter(r => r.type?.startsWith('icon')) ?? []).map(r => {
          const symbol = this.map.symbols.find(s => s.id === r.type);
          const resource = Object.assign(r, {
            width: symbol?.width ?? 2,
            height: symbol?.height ?? 2,
            layer: 'icons',
            transform: this.map.applyTransform(r),
          }) as MapSymbolElement;
          resource.style = `transform: ${r.transform}; transform-origin: ${r.width! / 2}px ${r.height! / 2}px;`;
          return resource;
        }),
  );
  doors = computed<MapDoorElement[]>(() =>
    this._internalConfig() == null || this.type == 'minimap'
      ? []
      : (this._internalConfig()!.resources.filter(r => r.type?.startsWith('door')) ?? []).map(r => {
          return Object.assign(r, { layer: 'doors' }) as MapDoorElement;
        }),
  );
  zones = computed<MapZoneElement[]>(() =>
    this._internalConfig() == null || this.type == 'minimap'
      ? []
      : (this._internalConfig()!.resources.filter(r => r.type?.startsWith('zone')) ?? []).map(r => {
          return Object.assign(r, {
            layer: 'zones',
            transform: this.map.applyTransform(r),
            filter: this.map.getFilter(r),
          }) as MapZoneElement;
        }),
  );
  labels = computed<MapLabelElement[]>(() =>
    this._internalConfig() == null
      ? []
      : (this._internalConfig()!.resources.filter(r => r.type === 'label') ?? []).map(r => {
          const label = Object.assign(r, { layer: 'label', transform: this.map.applyTransform(r) }) as MapLabelElement;
          label.style = `transform: ${r.transform}; transform-origin: 0px 0px;`;
          return label;
        }),
  );
  neighborhoods = computed<Neighborhood[]>(() =>
    this._internalConfig() == null || this.type == 'minimap'
      ? []
      : (this._internalConfig()!.neighborhoods || []).map(n => {
          n.safeName = `_${underscore(n.name).replace('.', '')}`;
          n.alpha = (getAlphaValue(n.colorCode) / 255) * 100;
          n.d = this.getPathFromHood(n);
          return n;
        }),
  );

  hoverResource = signal<MapResource | undefined>(undefined);
  hoverColleague = signal<ColleagueBookings | undefined>(undefined);

  optimizeX = this.map.optimizeX;
  optimizeY = this.map.optimizeY;

  abbreviate = abbreviate;

  youAreHere = computed<MapResource | undefined>(() => {
    if (this.resourceID() != null) {
      let youAreHeareResource;
      if (Array.isArray(this.resourceID())) {
        youAreHeareResource = this.resources().find(r => (this.resourceID() as number[]).includes(r.resourceID || -1));
      } else {
        youAreHeareResource = this.resources().find(r => r.resourceID === this.resourceID());
      }
      if (youAreHeareResource)
        return Object.assign(youAreHeareResource, {
          selected: true,
          style2: this.map.applyPosition(youAreHeareResource, -11, 4),
        }) as MapSymbolElement;
    }
    return undefined;
  });
  selectedNeighborhood?: Neighborhood;

  canvas = viewChild<ElementRef<SVGGElement>>('canvas');
  private _canvasEl?: SVGSVGElement;
  get canvasEl(): SVGSVGElement {
    // If no canvas is set, try to set it
    if (!this._canvasEl) this._canvasEl = this.canvas()?.nativeElement.querySelector('svg') || undefined;
    // If no canvas was found, shortcut to parent svg temporarilly
    if (!this._canvasEl) return this.canvas()?.nativeElement.closest('svg') as SVGSVGElement;
    // Return the canvas
    return this._canvasEl as SVGSVGElement;
  }

  constructor() {
    effect(
      async () => {
        // Use provided map config, ...
        if (this.config()) this._internalConfig.set(structuredClone(this.config()) as MapConfig);
        // ... or load map config
        else if (this.configUrl() && this.mapID() != null) {
          try {
            this._internalConfig.set(
              structuredClone(await firstValueFrom(this.map.getConfig(`${this.configUrl()}/${this.mapID()}`))),
            );
          } catch (ex) {
            this.snack.open('Warning', 'Could not load map', { duration: 5000 });
          }
        }
        // Die if none are provided
        else return;
        // Exclude loading config from the performance measurement
        performance.mark('map-config-start');
      },
      { allowSignalWrites: true },
    );
    effect(() => {
      // Check if we need to zoom into neighborhoods before desks can be selected
      if (this._internalConfig() != null && this.resources().length > 0) {
        if (this.interactive) {
          this.canSelectResource = !(this._zoomEnabled() === true);
          this.autoScroll();
        } else if (this.resourceID() != null || this.markResourceID() != null) {
          this.autoScroll();
        }
        if (!this.rendered) {
          this.rendered = true;
          this.mapLoaded.emit(this._internalConfig()!);
          this.mapLoaded$.next(this._internalConfig());
          waitForElement(`#elementID_${this.resources().at(-1)?.mapElementID}`).then(elm => {
            // Emit this only when the last element is visible in the DOM
            logger(
              `Render: ${performance.measure('map-rendered', 'map-config-start').duration}`,
              'MAP',
              debug,
              'green',
            );
            this.resources().forEach(r => {
              if (this.type != 'minimap') {
                this.optimizeTextPosition(r, r.resourceNameAlias || r.resourceName || '', 0);
              }
            });

            this.mapRendered.emit(true);
          });
        }
      }
    });
    effect(() => {
      if (this.colleagues().length && this.resources().length) {
        this.decorateColleagues(this.colleagues(), this.resources());
        this.markChanged();
      }
    });
    effect(() => {
      if (this.focusOn() === 'neighborhood' && this.myZoneID()?.length) {
        const hood = this.neighborhoods().find(h => +h.zoneID === +this.myZoneID()![0]);
        if (hood) {
          const el = document.querySelector(`#${hood.safeName}`) as SVGElement;
          this.zoomTo(el);
        }
      }
    });
  }

  async ngOnChanges(change: SimpleChanges) {
    if (change.viewStateChange?.currentValue && this.viewStateChange != null) {
      this.subscriptions.push(
        this.viewStateChange.subscribe(state => {
          if (
            state === ViewState.FULLSCREEN &&
            ['all', 'youAreHere'].includes(this.focusOn()) &&
            this.youAreHere() != null
          ) {
            this.autoScroll();
          }
          if (['all', 'neighborhood'].includes(this.focusOn()) && this.selectedNeighborhood != null) {
            const el = document.querySelector(`#${this.selectedNeighborhood.safeName}`) as SVGElement;
            this.zoomTo(el);
          }
        }),
      );
    }
  }

  private decorateColleagues(colleagues = this.colleagues(), resources = this.resources()) {
    colleagues
      .filter(c => c.transform == null || (c.textX == null && c.textY == 0))
      .forEach(async c => {
        c.abbreviation = abbreviate(c.name);
        c.domain = this.fetchDomain(c.name);
        // Find resource this booking is for
        const resource = resources.find(r => r.resourceID === c.resourceID);
        if (!resource) return;

        resource.isColleague =
          (this.markResourceID() != null && resource.resourceID === this.markResourceID()) ||
          (this.type === 'minimap' && this.isColleague(resource));
        if (resource.isColleague) {
          if (!resource.classList) resource.classList = [];
          resource.classList.push('colleague');
        }

        // Attach the colleague to this resource
        const transform = this.map.applyTransform(resource, 0);
        Object.assign(c, resource, {
          style: `transform: ${transform}; transform-origin: ${(resource.width || 2) / 2}px ${
            (resource.height || 2) / 2
          }px`,
        });
        if (this.type != 'minimap') {
          this.optimizeTextPosition(c, c.isGuest ? this.fetchDomain(c.name) : abbreviate(c.name), 10);
        }
        // Apply colleague image
        if (c.isGuest) c.imageUrl = c.thumbUrl = '/media/asset/guest.svg';
        else if (!c.imageUrl) c.imageUrl = c.thumbUrl = '/media/asset/person.png';
        else if (!c.thumbUrl && c.imageUrl != null) {
          const file = c.imageUrl.substring(0, c.imageUrl.lastIndexOf('.'));
          const ext = c.imageUrl.substring(c.imageUrl.lastIndexOf('.') + 1);
          c.thumbUrl = `${file}.thumbnail.${ext}`;
        }
        c.cx = (c.width || 2) / 2 + 0.5;
        c.cy = (c.height || 2) / 2 + 0.5;
        c.r = (c.width || 2) / 3 - 0.5;

        // Set hover box width
        const canvas = this.canvas()!.nativeElement.closest('svg') as SVGSVGElement;
        const colleagueBox = this.map.getDimensions(c.name, canvas);
        const resourceBox = this.map.getDimensions(resource.resourceNameAlias || resource.resourceName || '', canvas);
        c.textWidth = Math.max(colleagueBox.width, resourceBox.width) + 25;
        c.isRightAligned = resource.x + 30 + c.textWidth > canvas.getBBox().width;
      });
    return colleagues;
  }

  markChanged() {
    this.cdr.markForCheck();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.display = true;
      this.markChanged();
    });
  }

  optimizeTextPosition(r: MapResource, text: string, offset = 20) {
    const canvas = this.canvas()!.nativeElement.closest('svg') as SVGSVGElement;
    const [newX, newY] = this.map.optimizeTextPosition(
      text,
      canvas,
      r.x,
      r.y,
      // r.x + (r.width || 2) / 2,
      // r.y + (r.height || 2) / 2,
      r.width || 0,
      r.height || 0,
      offset,
    );
    r.textX = newX;
    r.textY = newY;
  }

  ngOnDestroy() {
    this.display = false;
  }

  fetchDomain(name: string) {
    if (name == null) return '';
    // eslint-disable-next-line no-useless-escape
    const match = name.match(/(@[a-zA-Z1-9\._-]*)/);
    const domain = match ? match[1].substring(0, match[1].lastIndexOf('.')) : name;
    return `${domain}`.toUpperCase();
  }

  getPathFromHood(hood: Neighborhood) {
    const path = hood.vectors
      ?.filter(n => n != null && 'x' in n)
      .map((v, i) => `${i == 0 ? 'M' : 'L'}${v.x},${v.y}`)
      .join(' ');
    return path?.length ? `${path}Z` : '';
  }

  isColleague(resource: MapResource): boolean {
    return (
      this.colleagues()?.length > -1 && this.colleagues().findIndex(c => c.resourceID === resource.resourceID) > -1
    );
  }

  hideAllTitles(evt?: Event) {
    if (!this.rendered) return;
    if (evt) evt.stopPropagation();
    this.resources().forEach(r => (r.titleShown = false));
    this.hoverResource.set(undefined);
    this.hoverColleague.set(undefined);
  }

  hideTitle(r: MapResource, evt?: Event) {
    if (!this.rendered) return;
    if (evt) evt.stopPropagation();
    r.titleShown = false;
    if (this.hoverResource()?.resourceID === r.resourceID) this.hoverResource.set(undefined);
    if (this.hoverColleague()?.resourceID === r.resourceID) this.hoverColleague.set(undefined);
  }

  showTitle(r: MapResource, evt?: Event) {
    if (!this.rendered) return;
    if (evt) evt.stopPropagation();
    this.hideAllTitles();
    if (r.titleShown || !this.canSelectResource) return;

    r.titleShown = true;
    this.hoverResource.set(r);
    this.hoverColleague.set(this.colleagues().find(c => c.resourceID === r.resourceID));
  }

  getLimit(r: MapResource) {
    return this.map.getLimit('temperature', r.state?.value);
  }

  private _isAvailable(r: MapSymbolElement) {
    if (this.selectableTypes().includes(r.type) && r.resourceID != null) {
      return this.isAvailable != null
        ? this.isAvailable(r)
        : ('unavailable' in r && 'available' in r && !r.unavailable && r.available) || true;
    }
    return false;
  }

  _isFavorite(r: MapSymbolElement) {
    return this.isFavorite != null ? this.isFavorite(r) : false;
  }

  /**
   * Transform positions to point in svg coordinate system
   */
  toPoint(x: number, y: number): Point {
    if (!this.canvasEl || !('createSVGPoint' in this.canvasEl)) {
      // This will never happen, except in unit testing
      return { x, y };
    }

    const point = this.canvasEl.createSVGPoint();
    if (!point) {
      return { x: 0, y: 0 };
    }
    point.x = x;
    point.y = y;
    return point.matrixTransform(this.canvasEl.getCTM()?.inverse());
  }

  // My own click event handler because Angular's click event is not always working on iOS devices
  downAt: Point = { x: 0, y: 0 };
  pointerDown(r: MapResource, evt: PointerEvent) {
    this.downAt = this.toPoint(evt.clientX, evt.clientY);
  }

  pointerUp(r: MapResource, evt: PointerEvent) {
    const upAt = this.toPoint(evt.clientX, evt.clientY);
    if (Math.abs(this.downAt.x - upAt.x) < 5 && Math.abs(this.downAt.y - upAt.y) < 5) {
      this.select(r, evt);
    }
  }

  select(r: MapResource, evt: MouseEvent) {
    if (!this.rendered) return;
    if (!r.titleShown) this.showTitle(r, evt);
    if (this.interactive && this.rendered && this.canSelectResource) {
      evt.stopPropagation();
      if (this.selectableTypes().includes(r.type) && r.state?.booked !== true) {
        r = r as MapSymbolElement;
        if (r.available === false || r.unavailable === true) return;
        evt.preventDefault();
        evt.stopImmediatePropagation();
        this.selectionMade.emit(r);
      }
    }
    // r.titleShown = false;
  }

  resetNeighborhood() {
    // Programmatic zoom out
    this.selectedNeighborhood = undefined;
    this.zoom = 1;
    if (this.interactive) {
      this.autoScroll();
      this.canSelectResource = !(this._internalConfig()!.enableZoom === true);
    }
    this.isZoomed = false;
  }

  getMyZoneElement(): Element {
    return this.rootElement.nativeElement.querySelector('.myZone');
  }

  @Denounced(50)
  private autoScroll(scrollPadding = 0, position: ScrollPosition = 'center') {
    let elm: Element | null = null;
    if (!elm && this.resourceID()) {
      elm = this.rootElement.nativeElement.querySelector('.youAreHere') as SVGElement;
    }
    if (!elm && this.markResourceID()) {
      elm = document.querySelector(`.resourceID_${this.markResourceID()}`);
    }
    if (!elm && this.selectedNeighborhood) {
      elm = document.querySelector(`#${this.selectedNeighborhood.safeName}`);
    }
    if (!elm && this.myZoneID()) {
      elm = this.getMyZoneElement();
    }
    if (elm == null) {
      elm = this.rootElement.nativeElement;
      position = position || 'left';
    }
    if (elm != null) {
      this.ngZone.runOutsideAngular(() => {
        elm != null &&
          scrollIntoView({
            elm: elm,
            containerSearchFrom: this.rootElement.nativeElement.parentElement,
            scrollPadding,
            position: position,
          });
      });
    }
  }

  selectNeighborhood(hood: Neighborhood, evt: MouseEvent, force = false) {
    if (!this.rendered) return;
    if (this.interactive && this._internalConfig()!.enableZoom === true && (!this.canSelectResource || force)) {
      evt.stopPropagation();

      // Select neighborhood and emit selection
      this.selectedNeighborhood = hood;
      const hoodEl = evt?.target as SVGSVGElement; // This should be the actual path element for this neighborhood
      const rootEl = this.zoomTo(hoodEl);

      // Activate resource selection
      this.canSelectResource = true;
      this.zoneSelectionMade.emit({ hood, rootEl, hoodEl });
      resetZoom();
    }
  }

  zoomTo(elm: SVGElement) {
    if (elm == null) return;
    this.isZoomed = true;

    // Scale and scroll to neighborhood
    const rootEl = this.rootElement.nativeElement;
    // The viewport to scale this element to
    const viewport = this.getOverflowParent(rootEl);
    // Get the width & height of the element
    const elBox = (elm as SVGSVGElement).getBoundingClientRect();
    // Get the width & height of the viewport
    const viewportHeight = viewport.clientHeight;
    const viewportWidth = viewport.clientWidth;
    // Calculate the scale
    const elmHeight = viewportHeight / elBox.height;
    const elmWidth = viewportWidth / elBox.width;
    // Zoom to the smallest scale
    this.zoom = Math.max(1, Math.min(elmHeight, elmWidth));
    this.autoScroll();
    return rootEl;
  }

  getBox(elm: Element) {
    if ('getBBox' in elm) return (elm as SVGSVGElement).getBBox();
    const rect = elm.getBoundingClientRect();
    const topLeft = this.toPoint(rect.left, rect.top);
    const bottomRight = this.toPoint(rect.right, rect.bottom);
    return {
      x: topLeft.x,
      y: topLeft.y,
      width: bottomRight.x,
      height: bottomRight.y,
    } as SVGRect;
  }

  getOverflowParent(element = this.rootElement.nativeElement) {
    let parent = element.parentElement;
    while (parent && (parent.scrollHeight < parent.clientHeight || parent.scrollWidth < parent.clientWidth)) {
      parent = parent.parentElement;
    }
    return parent;
  }
}

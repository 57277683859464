<svg
  version="1.0"
  [attr.viewBox]="coordinates()"
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  (click)="hideAllTitles($event)"
>
  <!-- Reusable elements -->
  <defs>
    <!-- Drop shadow on resources -->
    <filter id="drop-shadow">
      <feGaussianBlur in="SourceAlpha" stdDeviation="1.8" />
      <feOffset dx="0" dy="0" result="offsetblur" />
      <feFlood id="drop-shadow-flood" floodColor="rgb(0,0,0)" floodOpacity="0.75" />
      <feComposite in2="offsetblur" operator="in" />
      <feMerge>
        <feMergeNode />
        <feMergeNode in="SourceGraphic" />
      </feMerge>
    </filter>

    <!-- SVG filter to add an outline around text using the current background css variable -->
    <!-- This replaces text stroke and paint-order as this was buggy on safari -->
    <filter id="outline">
      <feMorphology operator="dilate" radius="0.75" in="SourceAlpha" result="expanded" />
      <feFlood flood-color="var(--background)" result="expanded-color" />
      <feComposite in="expanded-color" in2="expanded" operator="in" result="outline" />
      <feMerge>
        <feMergeNode in="outline" />
        <feMergeNode in="SourceGraphic" />
      </feMerge>
    </filter>

    <!-- Stripes -->
    <pattern id="partly" viewBox="0,0,8,8" width="64" height="64" patternUnits="userSpaceOnUse">
      <polygon points="0,0 4,0 0,4" class="back"></polygon>
      <polygon points="0,8 8,0 8,4 4,8" class="back"></polygon>
      <polygon points="0,4 0,8 8,0 4,0" class="front"></polygon>
      <polygon points="4,8 8,8 8,4" class="front"></polygon>
    </pattern>
    <pattern id="blocked" viewBox="0,0,8,8" width="16" height="16" patternUnits="userSpaceOnUse">
      <polygon points="0,0 4,0 0,4" class="back"></polygon>
      <polygon points="0,8 8,0 8,4 4,8" class="back"></polygon>
      <polygon points="0,4 0,8 8,0 4,0" class="front"></polygon>
      <polygon points="4,8 8,8 8,4" class="front"></polygon>
    </pattern>
    <pattern id="occupied-blocked" viewBox="0,0,8,8" width="16" height="16" patternUnits="userSpaceOnUse">
      <polygon points="0,0 4,0 0,4" class="back"></polygon>
      <polygon points="0,8 8,0 8,4 4,8" class="back"></polygon>
      <polygon points="0,4 0,8 8,0 4,0" class="front"></polygon>
      <polygon points="4,8 8,8 8,4" class="front"></polygon>
    </pattern>
    <pattern id="selected-blocked" viewBox="0,0,8,8" width="16" height="16" patternUnits="userSpaceOnUse">
      <polygon points="0,0 4,0 0,4" class="back"></polygon>
      <polygon points="0,8 8,0 8,4 4,8" class="back"></polygon>
      <polygon points="0,4 0,8 8,0 4,0" class="front"></polygon>
      <polygon points="4,8 8,8 8,4" class="front"></polygon>
    </pattern>

    <!-- Temperature zones -->
    <radialGradient id="warmest-zone">
      <stop offset="55%" style="stop-color: var(--warmest)" stop-opacity="1" />
      <stop offset="100%" class="transparent-stop" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="warmer-zone">
      <stop offset="55%" style="stop-color: var(--warmer)" stop-opacity="1" />
      <stop offset="100%" class="transparent-stop" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="cool-zone">
      <stop offset="55%" style="stop-color: var(--cool)" stop-opacity="1" />
      <stop offset="100%" class="transparent-stop" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="selected-zone">
      <stop offset="55%" stop-color="#a4e5b4" stop-opacity="1" />
      <stop offset="100%" class="transparent-stop" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="hover-zone">
      <stop offset="55%" stop-color="#ff270020" stop-opacity="1" />
      <stop offset="100%" class="transparent-stop" stop-opacity="0" />
    </radialGradient>
    <radialGradient id="notActive-zone">
      <stop offset="55%" stop-color="#ffffff00" stop-opacity="0" />
      <stop offset="100%" stop-color="#ffffff00" stop-opacity="0" />
    </radialGradient>

    <symbol id="youAreHere">
      <circle cx="12" cy="12" r="10" style="fill: var(--hereIndicator, red)"></circle>
    </symbol>

    <!-- Static elements -->
    <!-- Add only icon definitions which actually are in use -->
    @for (symbol of symbols(); track symbol.id) {
      <symbol
        [attr.id]="symbol.id"
        [attr.width]="symbol.width"
        [attr.height]="symbol.height"
        [attr.viewbox]="symbol.viewbox"
        [innerHTML]="symbol.safeSymbol"
      ></symbol>
    }
  </defs>
  <!-- Draw up base SVG -->
  <g id="canvas" #canvas [innerHTML]="floorPlanSVG()" />
  @if (showZones()) {
    <g id="neighborhood" class="neighborhood">
      @for (hood of neighborhoods(); track hood.zoneID) {
        <path
          [id]="hood.safeName"
          [style]="'--hoodColor: ' + (hood.colorCode ? hood.colorCode : 'var(--accent)')"
          [ngClass]="{ myZone: myZoneID()?.includes(hood.zoneID) }"
          [attr.d]="hood.d"
          (click)="selectNeighborhood(hood, $event)"
          (pointerdown)="selectNeighborhood(hood, $event)"
        ></path>
      }
    </g>
  }

  <!-- Place dynamic elements -->
  <!-- This is temperature zones -->
  @if (visibleLayers().includes('zones') && !showZones()) {
    <g id="zones" class="zones">
      @for (r of zones(); track r.mapElementID) {
        @if (r.filter !== 'notActive') {
          <circle
            [attr.id]="'elementID_' + r.mapElementID"
            [attr.style]="'transform: ' + r.transform"
            cx="0"
            cy="0"
            r="75"
            fill-opacity="0.7"
            [attr.fill]="'url(#' + r.filter + '-zone)'"
          ></circle>
          @if (r.type === 'zone_text' && r.state?.value != null) {
            <text
              #elm
              [attr.x]="optimizeX(r.x, elm, undefined, r.state?.value + ' °C')"
              [attr.y]="optimizeY(r.y, elm, undefined, r.state?.value + ' °C')"
              text-anchor="middle"
              dy=".3em"
              filter="url(#outline)"
            >
              {{ r.state?.value }} °C
            </text>
          }
        }
      }
    </g>
  }

  <!-- Map symbols like workstations and meetingrooms -->
  @if (visibleLayers().includes('resources')) {
    <g id="Resources" class="icons" [ngClass]="{ canSelect: interactive && canSelectResource }">
      @for (r of resources(); track r.mapElementID) {
        <use
          [attr.id]="'elementID_' + r.mapElementID"
          [attr.xlink:href]="'#' + r.type"
          [attr.style]="r.style"
          [class]="r.classList?.join(' ')"
          [attr.filter]="r.filter"
          (click)="select(r, $event)"
          (pointerdown)="pointerDown(r, $event)"
          (pointerup)="pointerUp(r, $event)"
          (mouseenter)="showTitle(r, $event)"
          (mouseleave)="hideTitle(r, $event)"
        ></use>
      }
      @if (youAreHere() != null) {
        <use class="youAreHere" [attr.xlink:href]="'#youAreHere'" [attr.style]="youAreHere()!.style2"></use>
      }
    </g>
  }
  @if (visibleLayers().includes('doors')) {
    <g id="Doors" class="thin doors">
      @for (r of doors(); track r.mapElementID) {
        <line
          [attr.x1]="r.x"
          [attr.x2]="r.x2"
          [attr.y1]="r.y"
          [attr.y2]="r.y2"
          [attr.data-resourceid]="r.mapElementID"
          [class]="r.state?.value"
        />
      }
    </g>
  }
  @if (visibleLayers().includes('icons')) {
    <g id="icons" class="icons">
      @for (r of icons(); track r.mapElementID) {
        <use [attr.id]="'elementID_' + r.mapElementID" [attr.xlink:href]="'#' + r.type" [attr.style]="r.style"></use>
      }
    </g>
  }
  @if (type !== 'minimap') {
    <g id="colleagues">
      @for (colleague of colleagues(); track colleague.employeeID) {
        @if (colleague.employeeID !== hoverColleague()?.employeeID) {
          <ng-container *ngTemplateOutlet="colleagueTpl; context: { $implicit: colleague }"></ng-container>
        }
      }
    </g>

    <g id="ResourceTitles">
      <!-- Highlight a resource specifically -->
      @if (markResource() != null && markResourceID() !== hoverResource()?.resourceID) {
        <g
          class="label booking"
          [style]="
            'translate: ' +
            (markResource()!.textX || markResource()!.x) +
            'px ' +
            (markResource()!.textY || markResource()!.y) +
            'px'
          "
        >
          <text #elm text-anchor="left" dy=".1em" filter="url(#outline)">{{ markResourceName() }}</text>
        </g>
      }
      <!-- Names for resources like workstations -->
      @if (interactive && canSelectResource) {
        @for (r of selectableResources(); track r.mapElementID) {
          @if (r.titleShown && hoverColleague()?.resourceID !== r.resourceID) {
            <g class="label workspace" [style]="'translate: ' + (r.textX || r.x) + 'px ' + (r.textY || r.y) + 'px'">
              @if (r.titleShown) {
                <text #elm text-anchor="left" dy=".1em" [attr.id]="'textID_' + r.mapElementID" filter="url(#outline)">
                  {{ r.resourceNameAlias || r.resourceName }}
                </text>
              }
            </g>
          }
        }
      }
    </g>
  }
  <g id="labels">
    @for (label of labels(); track label.mapElementID) {
      <text [attr.id]="'elementID_' + label.mapElementID" filter="url(#outline)" [attr.style]="label.style">
        {{ label.content }}
      </text>
    }
    @if (hoverColleague() != null) {
      <ng-container *ngTemplateOutlet="colleagueTpl; context: { $implicit: hoverColleague() }"></ng-container>
    }
  </g>

  <ng-content></ng-content>

  @if (type === 'minimap' && viewport != null) {
    <rect
      class="viewport-map"
      [attr.x]="viewport.x || 0"
      [attr.y]="viewport.y || 0"
      [attr.width]="viewport.width"
      [attr.height]="viewport.height"
    ></rect>
  }
</svg>

<!-- Colleague template -->
<ng-template #colleagueTpl let-colleague>
  <svg:g
    id="colleague_{{ colleague.employeeID }}"
    [attr.style]="colleague.style"
    class="colleague"
    [ngClass]="{
      hovering: hoverResource()?.resourceID === colleague.resourceID,
      right: colleague.isRightAligned
    }"
  >
    @if (colleague.resourceID === hoverResource()?.resourceID) {
      <rect
        [attr.x]="colleague.isRightAligned ? colleague.textWidth * -1 - 14 : 0"
        y="-8"
        [attr.width]="colleague.textWidth + (colleague.isRightAligned ? 10 : 10) + 'pt'"
        height="45"
        rx="25"
        ry="25"
      />
    }
    <circle
      [id]="'rounded_' + colleague.employeeID"
      [attr.cx]="colleague.cx"
      [attr.cy]="colleague.cy"
      [attr.r]="colleague.r"
    />
    <clipPath [id]="'clip_' + colleague.employeeID">
      <use [attr.xlink:href]="'#rounded_' + colleague.employeeID" />
    </clipPath>
    <image
      [attr.href]="colleague.thumbUrl"
      (error)="colleague.thumbUrl = colleague.imageUrl"
      [attr.width]="colleague.width"
      [attr.height]="colleague.height"
      [attr.clip-path]="'url(#clip_' + colleague.employeeID + ')'"
      [ngClass]="{ selected: colleague.resourceID === hoverResource()?.resourceID }"
    />

    <!-- Text label -->
    <text
      #elm
      text-anchor="left"
      class="label"
      filter="url(#outline)"
      [attr.style]="'--x: ' + (colleague.textX - colleague.x) + 'pt; --y: ' + (colleague.textY - colleague.y) + 'pt'"
    >
      @if (colleague.isGuest) {
        <tspan>{{ colleague.domain }}</tspan>
      } @else {
        @if (colleague.resourceID === hoverResource()?.resourceID) {
          <tspan x="0" y="0">{{ colleague.name }}</tspan>
          <tspan x="0" y="20">{{ hoverResource()?.resourceNameAlias || hoverResource()?.resourceName }}</tspan>
        } @else if (colleague.resourceID !== markResourceID()) {
          <tspan x="0" y="0">{{ colleague.abbreviation }}</tspan>
        }
      }
    </text>
  </svg:g>
</ng-template>

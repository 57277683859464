import { Injectable, inject } from '@angular/core';
import { EmployeeParking } from './parking.model';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class ParkingCacheService {
  // Tiny in-memory cache of current parking state per date
  private myParkings = new Map<number, EmployeeParking | null>();
  sec = inject(DomSanitizer);

  private decorateParking(parking: EmployeeParking): EmployeeParking {
    if (parking?.assetImageUrl) {
      parking.safeAssetImage = this.sec.bypassSecurityTrustResourceUrl(parking.assetImageUrl);
    }
    return parking;
  }

  get(date: number) {
    const parking = this.myParkings.get(date);
    return parking != null ? this.decorateParking(parking) : parking;
  }

  set(date: number, parking: EmployeeParking | null) {
    parking = parking != null ? this.decorateParking(parking) : parking;
    this.myParkings.set(date, parking);
  }

  has(date: number) {
    return this.myParkings.has(date);
  }
}

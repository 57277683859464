import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes';
import { MapComponent } from './map.component';

@NgModule({
  declarations: [MapComponent],
  imports: [CommonModule, PipesModule, DirectivesModule, MatSnackBarModule, MatButtonModule, MatIconModule],
  exports: [MapComponent],
})
export class MapModule {}
